@import '../../variables';

$slick-btn__color-default: #ffcb42;
$slick-btn__color-active: #d25320;

.landing {
  &.engagement {
  
    .error-row {
      padding: 0 25px 15px;
      color: $red;
    }

    .error-container-enter {
      opacity: 0;
    }

    .error-container-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

    .error-container-exit {
      opacity: 1;
    }

    .error-container-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }

    .loader-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
      font-size: 1.05em;
      letter-spacing: 0.02em;
      line-height: 1.35em;
    }

    .desc {
      margin-top: 2.5vh;
    }

    .heading {
      align-items: flex-start;

      h1 {
        line-height: 1.1;
        width: 69%;
        margin-top: 8px;
      }

      img {
        width: 31%;
      }
    }

    .slider_wrapper {
      margin-top: -3vh;
    }

    .slick-track {
      height: 71vw;
    }

    .slick-slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 70vw;
      margin: 0 0 auto;

      .slider-item-engagement {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
      }

      img {
        margin: 0 auto;
      } 

      p {
        text-align: center;
        margin-top: 20px;
        opacity: 0;
      }
    
      &.slick-active {
        p {
          animation: fadeIn 1.5s ease-out forwards;
        }
      }
    }

    .slick-dots {
      li {
        width: 12px;
        height: 12px;
        margin: 7px 10px;

        button {
          background: $slick-btn__color-default;
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 100px;
        }

        &.slick-active {
          button {
            background: $slick-btn__color-active;
            transform: none;
          }
        }
      }
    }

    .button_wrapper {
      margin-top: 3vh;

      button {
        background-image: linear-gradient(
          270deg,
          rgb(148, 0, 95) 8%,
          rgb(190, 56, 53) 74%,
          rgb(232, 112, 10) 100%
        );
        border-radius: 100px;
        padding: 0 5px;
        min-width: 160px;

        span {
          margin: 0;
          font-weight: 800;
          font-size: 1.05em;
          display: block;
          line-height: 2.4;
          letter-spacing: 0.02em;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}