@import '../../variables';

.landing {
  .heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    img {
      width: 25%;
    }
    h1 {
      width: 70%;
    }
  }
  .desc {
    margin: 2vh 0 .5vh;
  }
  .button_wrapper {
    margin-top: 5vh;
    text-align: center;
    .cta {
      img {
        height: 30px;
      }
      span {
        margin: 3px 0 8px;
        display: inline-block;
      }
    }
  }
  .slider_wrapper {
    margin-top: -5vh;
  }
  .slider_center-card {
    width: 50vw;
    height: 50vw;
    position: absolute;
    margin-left: 25%;
    background-size: contain;
    background-position: center;
}
  .slider_center-card-winback {
    width: 50vw;
    height: 50vw;
    position: absolute;
    margin-left: 25%;
    background: url('../../assets/card_winback.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  .slider_price {
    background-color: $green;
    color: $white;
    font-size: 2.3em;
    display: inline-block;
    width: 65px;
    padding: 5px;
    text-align: center;
    border-radius: 3px;
    margin-top: 5vw;
    margin-left: 1vw;
    sub {
      font-size: 0.6em;
      margin-left: -2px;
      position: absolute;
    }
    &::before {
      content: '\20AC';
      font-size: .55em;
      position: absolute;
      margin-left: -9px;
      margin-top: 2px;
    }
  }
  .slider-item {
    width: 100%;
    padding: 5px;
    outline: none;
    img {
      max-height: 30vw;
      max-width: 40vw;
      margin: 0 auto;
      &.smaller-img {
        max-width: 30vw;
      }
    }
  }
  .slider-item-engagement {
    width: 100%;
    padding: 5px;
    outline: none;
    img {
      max-height: 48vw;
      max-width: 48vw;
      margin: -35px auto;
      &.smaller-img {
        max-width: 30vw;
      }
    }
  }
  .slick-track {
    height: 60vw;
  }
  .slick-list {
    width: 50%;
    overflow: visible;
    height: 50vw;
    margin: 15vw 0 30px 25%;
  }
  .slick-dots {
    display: block;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 7px;
      padding: 0;
      cursor: pointer;
      button {
        display: block;
        width: 100%;
        height: 100%;
        color: transparent;
        cursor: pointer;
        border: 0;
        outline: none;
        transition: transform .3s ease;
        &::before {
          content: none;
        }
      }
      &:first-child {
        button {
          background: url('../../assets/crumb1.png');
          background-size: cover;
          background-position: center;
        }
      }
      &:nth-child(2) {
        button {
          background: url('../../assets/crumb2.png');
          background-size: cover;
          background-position: center;
        }
}
      &:nth-child(3) {
        button {
          background: url('../../assets/crumb2.png');
          background-size: cover;
          background-position: center;
        }
      }
      &:nth-child(4) {
        button {
          background: url('../../assets/crumb2.png');
          background-size: cover;
          background-position: center;
        }
      }
      &:nth-child(5) {
        button {
          background: url('../../assets/crumb2.png');
          background-size: cover;
          background-position: center;
        }
      }
      &:last-child {
        button {
          background: url('../../assets/crumb3.png');
          background-size: cover;
          background-position: center;
        }
      }
      &.slick-active {
        button {
          transform: scale(1.5);
        }
      }
    }
  }
}
