/* COLORS */
@font-face {
  font-family: 'Speedee';
  font-weight: 300;
  src: url("assets/fonts/Speedee_W_Lt.woff") format("woff"); }

@font-face {
  font-family: 'Speedee';
  font-weight: 400;
  src: url("assets/fonts/Speedee_W_Rg.woff") format("woff"); }

@font-face {
  font-family: 'Speedee';
  font-weight: 700;
  src: url("assets/fonts/Speedee_W_Bd.woff") format("woff"); }

@font-face {
  font-family: 'Speedee Condensed';
  font-weight: 400;
  src: url("assets/fonts/SpeedeeCd_W_Rg.woff") format("woff"); }

@font-face {
  font-family: 'Speedee Condensed';
  font-weight: 700;
  src: url("assets/fonts/SpeedeeCd_W_Bd.woff") format("woff"); }

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body, html {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden; }

.App {
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: none;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
  font-family: 'Arial', sans-serif;
  font-size: 4vw;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .App section {
    background-color: #fff;
    min-height: 100vh;
    height: auto;
    width: 100vw;
    overflow-x: hidden;
    padding: 0;
    overflow-x: hidden; }
    .App section .wrapper {
      padding: 4vh 25px 0; }
  .App button.cta {
    font-family: 'Speedee', sans-serif;
    font-size: 1.125em;
    color: #fff;
    background-color: #254f36;
    border: none;
    outline: none;
    padding: 4px 25px 0;
    border-radius: 3px;
    margin-bottom: 20px;
    min-width: 170px;
    width: 37%; }
  .App p {
    color: #2d2d2d;
    line-height: 1.3em;
    letter-spacing: 0.03em; }
  .App h1 {
    color: #2d2d2d;
    font-family: 'Speedee', sans-serif;
    font-weight: 700;
    font-size: 2.5em;
    letter-spacing: 0.03em; }
  .App .label {
    font-family: 'Speedee Condensed', sans-serif;
    font-weight: 700; }
  .App .slick-slider {
    width: 100%; }
  .App .slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 40vw; }
